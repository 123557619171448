/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';
import NavBar from '../components/nav';
import {withAnalytics} from '../modules/analytics/analytics';
import useTranslation from 'next-translate/useTranslation';
import {WithAnalytics} from '../lib/analytics/analytics';
import {StyledProps} from '../modules/common/styles/props-interface';
import {mediaQueries} from '../modules/common';
import {H2} from '../components/headings';
import CommonContentContainer from '../components/common/common-content-container';
import FonomaDefaultSeo from '../components/seo/fonoma-default-seo';

import styled from 'styled-components';
import Footer from '../components/footer/footer-controller';
import {
    typographyHeading2Styles,
    typographyLargeBodyStyles,
    typographyTitleBodyStyles
} from "../components/typography.styles";
import {GradientBackground, GradientType} from "../components/gradient-background/gradient-background";
import Link from 'next/link'
import AboutGlobalStyle from '../global-styles/about/about-global-style';

interface Props extends WithAnalytics, StyledProps {}

const RelativeContainer = styled.div`
    position: relative;
`;

const NotFoundAContentContainer = styled(CommonContentContainer)`
    background: unset;
    border: none;
    position: relative;
`;

const StyledNavBar = styled(NavBar)`
    background-color: unset;
    box-shadow: none;
    
    ${mediaQueries.lessThan('small')`
        min-height: 64px;
    `};
`;

//Components...
const Unstyled404Page = (props: Props): JSX.Element => {
    const {className} = props;
    const {t} = useTranslation('404');
    const staticImage = `${process.env.NEXT_PUBLIC_ASSETS_URL}/404_2x.webp`;

    return (
        <>
            <AboutGlobalStyle />
            <FonomaDefaultSeo />
            <RelativeContainer>
                <GradientBackground gradient={GradientType.NotFound}/>
                <div>
                    <NotFoundAContentContainer>
                        <section className={className}>
                            <div>
                                <img src={staticImage} />
                                <H2 $textSize={24}>{t('this-page-can-not-be-found')}</H2>
                                <a href="/">{t('go-to-fonoma')}</a>
                            </div>
                        </section>
                    </NotFoundAContentContainer>
                    <StyledNavBar/>
                    <Footer />
                </div>
            </RelativeContainer>
            
        </>
    );
};

//Styles...
const Styled404Page = styled(Unstyled404Page)`
    padding: 198px 0;

    ${mediaQueries.lessThan('small')`
        padding: 128px 16px;
    `};

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h2{
        ${typographyHeading2Styles}
        color: ${(props): string => props.theme.colors.ballena};
        margin: 32px 0;
    }
    img{
        vertical-align: middle;
        ${typographyLargeBodyStyles}
        height: 221px;
    }
    a{
        ${typographyTitleBodyStyles}
        border-radius: 24px;
        padding: 12px 24px;
        border: none;
        text-align: center;
        color: ${(props): string => props.theme.colors.arroz};
        background-color:${(props): string => props.theme.colors.plancton};

        &:hover,
        &:focus{
            background-color:${(props): string => props.theme.colors.plancton};
            color: ${(props): string => props.theme.colors.arroz};
        }

        &:active,
        &.active{
            background-color:${(props): string => props.theme.colors.plancton};
        }

    }
`;

export default withAnalytics(Styled404Page);
